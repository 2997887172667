<template>
  <div>
    <el-card>
      <div>
        <div class="account">会员账号：<el-input
            class="input"
            placeholder=""
            v-model="queryInfo.mobile"
            @change="getRegisterList"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getRegisterList"
            ></el-button>
          </el-input>
        </div>
        <div class="btn">
          <el-button
            type="primary"
            size="small"
            @click="addRegisterList"
          >登记</el-button>
        </div>
      </div>
      <el-table
        border
        stripe
        :data="registerList"
      >
        <el-table-column
          label="id"
          prop="id"
          width="80"
        ></el-table-column>
        <el-table-column
          label="会员账号"
          prop="mobile"
        ></el-table-column>
        <el-table-column
          label="会员昵称"
          prop="memberName"
        ></el-table-column>
        <el-table-column
          label="会员类型"
          prop="memberGrade"
        ></el-table-column>
        <el-table-column
          label="登记时间"
          prop="createTime"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        activityId: '',
        limit: 15,
        pageNo: 1,
        mobile: ''
      },
      registerList: [],
      total: 0,
      registerForm: {
        activityId: '',
        mobile: ''
      }
    }
  },
  created () {
    this.queryInfo.activityId = this.$route.query.id
    this.getRegisterList()
    // console.log(this.queryInfo.activityId)
  },
  methods: {
    async getRegisterList () {
      const res = await this.$http.get('/offlineActivityRecord/list', { params: this.queryInfo })
      this.registerList = res.data.data.data
      this.total = res.data.data.total
      // console.log(this.registerList)
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getDistribution()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getDistribution()
    },
    async addRegisterList () {
      this.registerForm.activityId = parseInt(this.$route.query.id)
      this.registerForm.mobile = this.queryInfo.mobile
      const { data: res } = await this.$http.post('/offlineActivityRecord', this.registerForm)
      if (res.code === 0) {
        this.$message.success('新增成功')
        this.queryInfo.mobile = ''
        this.getRegisterList()
      } else {
        this.$message.error('新增失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.account {
  width: 330px;
  .input {
    width: 230px;
  }
}
.btn {
  margin-left: 350px;
  margin-top: -35px;
}
</style>
